<template>
	<h1>Table</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-html">{{html}}</code></pre>
		<pre v-highlightjs><code class="language-javascript">
import BaseTable from '@/components/BaseTable'

export default {
	components: { BaseTable },
	data() {
		return {
			sort: this.$route.query.sort || 'index',
			rawData: []
		}
	},
	computed: {
		// Transform raw data into table data
		tableData() {
			const table = []
			rawData.forEach((artist, index) => {
				table[index] = [
					// String
					index,

					// Internal link
					{
						type: 'link',
						display: artist.name,
						to: { // },
					},

					// External link
					{
						type: 'link-out',
						display: 'Google Images',
						href: '',
						target: '_blank',
					},

					// String
					artist.artworkCount,
				]
			})

			// Create table header
			table.unshift([
				// Segular sort
				{
					type: 'sort',
					sort: this.sort.match(/^index$/) ? '-index' : 'index',
					display: 'Idx',
					sel: this.sort.match(/^-?index$/),
				},
				{
					type: 'sort',
					sort: this.sort.match(/^name$/) ? '-name' : 'name',
					display: 'Name',
					sel: this.sort.match(/^-?name$/),
				},

				// Not sortable
				'Profile',
				'Google Images',

				// Reverse default sort
				{
					type: 'sort',
					sort: this.sort.match(/^-artwork-count$/)
						? 'artwork-count'
						: '-artwork-count', // &#60;-- default
					display: 'Count',
					sel: this.sort.match(/^-?artwork-count$/),
				},
			])

			return table
		},
		watch: {
			sort(newValue) {
				this.$router.replace({
					name: this.$route.name,
					params: this.$route.params,
					query: { ...this.$route.query, sort: newValue },
				})
				// QUERY SERVER HERE WITH NEW SORT DATA
			},
		},
		created() {
			this.fetchRawData()
		},
		methods: {
			// Fetch raw data from server
			fetchRawData() {
				this.rawData = [
					{
						name: 'Pablo Picasso',
						namePath: 'pablo-picasso',
						country: 'Spain',
						artworkCount: 410,
					},
					{
						name: "Georgia O'Keeffe",
						namePath: 'georgia-okeeffe',
						country: 'USA',
						artworkCount: 345,
					},
					{
						name: 'René Magritte',
						namePath: 'rene-magritte',
						country: 'Belgium',
						artworkCount: 290,
					}
				]
			}
		}
	}
}
</code></pre>

		<pre v-highlightjs><code class="language-scss">{{css.join('\n')}}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Implementation</h3>
		<ul>
			<li>
				Source your raw data as an array, then transform it to an array with cell objects.
			</li>
			<li>
				Wrap your table component in a <span class="code">#table-wrap</span> div so you can set the width of
				the table and its columns.
			</li>
			<li>
				A cell can be simply text, or a cell object with a type parameter. Cell type can be
				<span class="code">link</span> (internal router-link) and <span class="code">link-out</span> (external
				link). You also can set the window <span class="code">target</span> to
				<span class="code">_blank</span>.
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<p>
			Note that the 'count' columm sorts reverse by default. Sorting is not hooked up because this would be
			handled by MongoDb and this is static data.
		</p>
		<div id="table-wrap">
			<BaseTable :data="tableData" v-model="sort" />
		</div>
	</div>
</template>

<script>
import BaseTable from '@/components/BaseTable'
import { injectMetaData } from '@/use/MetaData'
import flash from '@/use/FlashAlert'

export default {
	name: 'DocComponentsTable',
	components: { BaseTable },
	setup() {
		injectMetaData({
			title: 'Doc: Table Component',
		})
	},
	data() {
		return {
			sort: this.$route.query.sort || 'index',
			html: '<div id="table-wrap">\n	<BaseTable :data="tableData" v-model="sort" />\n</div>',
			css: [
				'// The wrap allows you to set the table and column widths',
				'#table-wrap {',
				'	width: 100%;',
				'	margin-bottom:0.3rem;',
				'}',
				'#table-wrap:deep() .table-cell:nth-child(1) {',
				'	width:0.6rem;',
				'}',
				'#table-wrap:deep() .table-cell:nth-child(2) {',
				'	width:1.2rem;',
				'}',
			],
		}
	},
	computed: {
		tableData() {
			const rawData = [
				{
					name: 'Pablo Picasso',
					namePath: 'pablo-picasso',
					country: 'Spain',
					artworkCount: 410,
				},
				{
					name: "Georgia O'Keeffe",
					namePath: 'georgia-okeeffe',
					country: 'USA',
					artworkCount: 345,
				},
				{
					name: 'René Magritte',
					namePath: 'rene-magritte',
					country: 'Belgium',
					artworkCount: 290,
				},
			]
			const table = []

			rawData.forEach((artist, index) => {
				table[index] = [
					index + 1,
					{
						type: 'link',
						display: artist.name,
						to: {
							name: 'AdminInspectEntity',
							params: {
								entityType: 'artist',
								id_username_namePath: artist.namePath,
							},
						},
					},
					{
						type: 'link',
						display: 'Profile',
						to: {
							name: 'Artist',
							params: { namePath: artist.namePath, category: artist.category },
						},
					},
					{
						type: 'link-out',
						display: 'Google Images',
						href: `https://www.google.com/search?q=${artist.name.replace(
							/ /g,
							'+'
						)}+art&tbm=ischhttps://google.com`,
						target: '_blank',
					},
					artist.artworkCount,
				]
			})

			// Create header
			// Table header
			table.unshift([
				{
					type: 'sort',
					sort: this.sort.match(/^index$/) ? '-index' : 'index',
					display: 'Idx',
					sel: this.sort.match(/^-?index$/),
				},
				{
					type: 'sort',
					sort: this.sort.match(/^name$/) ? '-name' : 'name',
					display: 'Name',
					sel: this.sort.match(/^-?name$/),
				},
				'Profile',
				'Google Images',
				{
					type: 'sort',
					sort: this.sort.match(/^-artwork-count$/) ? 'artwork-count' : '-artwork-count',
					display: 'Count',
					sel: this.sort.match(/^-?artwork-count$/),
				},
			])

			return table
		},
	},
	watch: {
		sort(newValue) {
			this.$router.replace({
				name: this.$route.name,
				params: this.$route.params,
				query: { ...this.$route.query, sort: newValue },
			})
			flash('Query server (fake data)', {
				expiration: 500,
			})
		},
	},
}
</script>

<style scoped lang="scss">
#table-wrap {
	width: 100%;
	margin-bottom: 0.3rem;
}
#table-wrap:deep() .table-cell:nth-child(1),
#table-wrap:deep() .table-cell:nth-child(5) {
	width: 0.6rem;
}
#table-wrap:deep() .table-cell:nth-child(3) {
	width: 0.8rem;
}
#table-wrap:deep() .table-cell:nth-child(4) {
	width: 1.2rem;
}
</style>
